<template>
  <div>
    <h2 class="card-header">Upcoming Events</h2>
    <div
      style="display: flex; flex-direction: column;justify-content: space-around; height: 100%;"
    >
      <EventItem
        :start="event.start_date"
        :end="event.end_date"
        :title="event.title"
        :category="event.type"
        :host="event.host"
        v-for="event in events"
      ></EventItem>

      <Spinner v-if="events.length <= 0" />
    </div>
  </div>
</template>

<script>
import EventItem from "./EventItem";
import Spinner from "./Spinner";

const RELOAD_INTERVAL = 1000 * 60 * 10; // 10 minutes

export default {
  components: { EventItem, Spinner },

  data() {
    return {
      events: []
    };
  },

  created() {
    this.getEventData();

    setInterval(this.getEventData, RELOAD_INTERVAL);
  },

  methods: {
    async getEventData() {
      try {
        let response = await window.axios.get(
          "https://www.startplatz.de/events/list?format=json"
        );

        this.events = response.data.splice(0, 6);
      } catch (error) {
        console.warn(error);
      }
    }
  }
};
</script>