<template>
  <div class="dashboard">
    <Events class="sidebar"></Events>
    <Connect class="main"></Connect>
    <div class="powered">
      <img src="/images/sp-connect.png" alt="SP Connect Logo" />
    </div>
  </div>
</template>

<script>
import Connect from "./Connect.vue";
import Events from "./Events.vue";

export default {
  components: {
    Connect,
    Events
  }
};
</script>