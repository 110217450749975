<template>
  <div class="startup" :class="isPerk ? 'is-perk' : ''" v-once>
    <div class="logo">
      <img :src="item.image" :alt="item.title + ' Image'" v-once />
    </div>
    <div v-show="isPerk" v-once>
      <img
        class="perk-stamp"
        src="/images/perk-stamp.png"
        alt="Startplatz Connect Community Perk"
      />
    </div>
    <div class="information">
      <h2 v-once>{{ item.title }}</h2>
      <p v-once>
        {{ item.description }}
      </p>
      <span
        v-show="item.industry"
        class="industry"
        :class="isPerk ? 'is-perk' : ''"
        v-once
        >{{ item.industry }}</span
      >
      <span v-show="item.location" class="meta" v-once
        ><img class="icon" src="/images/marker.png" alt="Marker Icon" />{{
          item.location
        }}</span
      >
      <span v-show="item.link" class="meta" v-once
        ><img class="icon" src="/images/link.png" alt="Link Icon" />{{
          strippedLink
        }}</span
      >
      <span v-show="item.deal" class="meta" v-once
        ><img class="icon" src="/images/tag.png" alt="Deal Icon" />{{
          item.deal
        }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    strippedLink() {
      try {
        return this.item.link
          .replace(/https?:\/\/(www\.)?/gm, "")
          .replace(/\/?$/, "");
      } catch (error) {
        return this.item.link;
      }
    },

    isPerk() {
      return this.item.deal && this.item.deal.length > 0;
    }
  }
};
</script>