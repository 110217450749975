<template>
  <div class="connect-wrapper" :style="translateTransform">
    <ConnectItem
      v-bind="{ item }"
      v-for="(item, index) in items"
      :key="index"
    ></ConnectItem>
  </div>
</template>


<script>
import ConnectItem from "./ConnectItem";

export default {
  components: { ConnectItem },
  data() {
    return {
      items: [],
      active: 0
    };
  },

  computed: {
    itemHeight() {
      return window.innerHeight / 2;
    },

    translateTransform() {
      let shift = Math.round(this.active * this.itemHeight);
      return `transform: translateY(-${shift}px)`;
    }
  },

  methods: {
    nextConnectItem() {
      if (this.active === this.items.length - 2) {
        this.active = 0;
        this.shuffleItems(this.items);
      } else {
        this.active += 1;
      }
    },

    shuffleItems(items) {
      window.shuffle(items);
      this.items = items;
    },

    async updateItems() {
      try {
        let perks = await this.getPerks();
        let startups = await this.getStartups();

        this.shuffleItems([...perks, ...startups]);
      } catch (error) {
        console.log("could not shuffle");
      }

      this.active = 0;
    },

    async getPerks() {
      try {
        let response = await window.axios.get(
          "https://www.startplatz.de/benefits/perks/home?format=json"
        );

        return response.data;
      } catch (error) {
        console.warn(error);
      }
    },

    async getStartups() {
      try {
        let response = await window.axios.get(
          "https://www.startplatz.de/x/connect/dashboard-member",
          {
            headers: { "x-dashboard": "welcome@startplatz" }
          }
        );

        return response.data;
      } catch (error) {
        console.warn(error);
      }
    }
  },

  created() {
    this.updateItems();
    setInterval(this.nextConnectItem, 12000);
  }
};
</script>