<template>
  <div class="event">
    <div>
      <div class="date">
        <span class="day">{{ dateDay }}</span>
        <span class="month">{{ dateMonth }}</span>
      </div>
    </div>
    <div class="information">
      <div class="location">
        <span class="time">{{ time }}</span>
        <span v-show="room" class="room">{{ room }}</span>
      </div>

      <h2>{{ title }}</h2>

      <div class="meta">
        <span>{{ category }}</span>
        <span v-show="host">| {{ host }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    start: {
      type: String,
      default: undefined
    },
    end: {
      type: String,
      default: undefined
    },
    room: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    category: {
      type: String,
      default: undefined
    },
    host: {
      type: String,
      default: undefined
    }
  },

  computed: {
    startDate() {
      return new Date(this.start);
    },
    endDate() {
      return new Date(this.end);
    },
    dateDay() {
      return this.startDate.getDate();
    },
    dateMonth() {
      switch (this.startDate.getMonth()) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";
        case 9:
          return "OCT";
        case 10:
          return "NOV";
        case 11:
          return "DEC";
      }
    },
    time() {
      let startHour = this.startDate.getHours().pad();
      let startMinute = this.startDate.getMinutes().pad();

      let endHour = this.endDate.getHours().pad();
      let endMinute = this.endDate.getMinutes().pad();

      return `${startHour}:${startMinute} - ${endHour}:${endMinute}`;
    }
  }
};
</script>