import Vue from 'vue';
import App from "./components/App.vue";
import axios from "axios";
window.axios = axios;

const app = new Vue({
    components: {
        App
    },
    render(h) {
        return h('app')
    },
    el: '#app'
});

Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
window.shuffle = function (a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}